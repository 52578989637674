import useSWR from "swr"
import axios from "axios"

const useRole = () => {
  const { data, error, isValidating } = useSWR(
    `/api/v1/users/operators/me`,
    url =>
      axios({
        withCredentials: true,
        method: "GET",
        url,
      }).then(res => res.data)
  )

  return {
    error,
    loading: false,
    operator: data?.operator,
  }
}

export default useRole
