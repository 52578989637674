import React from "react"
import { useTranslation } from "react-i18next"
import { Avatar, Button, Popover, Space, Spin } from "antd"
import { useUser } from "@auth0/nextjs-auth0"
import Link from "next/link"
import { DownOutlined } from "@ant-design/icons"
import { getUserAvatar } from "../../utils"
import classNames from "./user-menu.module.scss"

export interface UserMenuProps {}

const UserMenu: React.FC<UserMenuProps> = () => {
  const { t } = useTranslation()
  const { user, isLoading, error } = useUser()

  if (isLoading) {
    return <Spin spinning />
  }

  if (!user) {
    return (
      <Space>
        <Button type={"primary"}>
          <Link href="/api/auth/login">{t("button:signIn")}</Link>
        </Button>
      </Space>
    )
  }

  const userMenu = (
    <div>
      <Space direction="vertical" align="center">
        <Space direction="vertical" align="center">
          <Avatar size={120} src={getUserAvatar(user)} />
          <span>{user.email}</span>
        </Space>
        {/*<Link href={"/settings/account"} passHref>*/}
        {/*  <Button type={"primary"}>{t("button:manageYourAccount")}</Button>*/}
        {/*</Link>*/}
        <Link href={"/api/auth/logout"} passHref>
          <Button danger>{t("button:signOut")}</Button>
        </Link>
      </Space>
    </div>
  )

  return (
    <Space>
      {/*<Link href={"/settings"} passHref>*/}
      {/*  <Button className={classNames["setting-button"]}>*/}
      {/*    <SettingOutlined />*/}
      {/*  </Button>*/}
      {/*</Link>*/}
      <Popover content={userMenu} trigger="hover">
        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          <Space className={classNames.container}>
            <Avatar
              size={32}
              src={getUserAvatar(user)}
              className={classNames.avatar}
            />
            <span>{user.email}</span>
            <DownOutlined />
          </Space>
        </a>
      </Popover>
    </Space>
  )
}

export default UserMenu
