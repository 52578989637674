import "../../styles/globals.scss"
import "../../public/font/stylesheet.css"
import type { AppProps } from "next/app"
import { ConfigProvider, Spin } from "antd"
import it_IT from "antd/lib/locale/it_IT"
import { UserProvider } from "@auth0/nextjs-auth0"
import { SWRConfig } from "swr"
import { BasicLayout } from "../components/layout"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import Error from "next/error"
import moment from "moment"
import "moment/locale/it"

moment.locale("it")

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  const [loadingPage, setLoadingPage] = useState(false)

  const handleLoadingStart = () => {
    setLoadingPage(true)
  }

  const handleLoadingStop = () => {
    setLoadingPage(false)
  }

  useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: "#C25836",
      },
    })

    if (process.env.NODE_ENV !== "development") {
      // window.document.addEventListener("contextmenu", evt =>
      //   evt.preventDefault()
      // )
    }

    router.events.on("routeChangeStart", handleLoadingStart)
    router.events.on("routeChangeComplete", handleLoadingStop)
    router.events.on("routeChangeError", handleLoadingStop)

    return () => {
      router.events.off("routeChangeStart", handleLoadingStart)
      router.events.off("routeChangeComplete", handleLoadingStop)
      router.events.off("routeChangeError", handleLoadingStop)
    }
  }, [])

  if (pageProps.error) {
    return (
      <Error
        statusCode={pageProps.error.statusCode}
        title={pageProps.error.message}
      />
    )
  }

  return (
    <ConfigProvider locale={it_IT}>
      <UserProvider>
        <SWRConfig value={{ revalidateOnFocus: false }}>
          <BasicLayout>
            <Spin spinning={loadingPage}>
              <Component {...pageProps} />
            </Spin>
          </BasicLayout>
        </SWRConfig>
      </UserProvider>
    </ConfigProvider>
  )
  return
}

export default MyApp
