import { default as breadcrumb } from "./en_US/breadcrumb.json"
import { default as button } from "./en_US/button.json"
import { default as error } from "./en_US/error.json"
import { default as formError } from "./en_US/formError.json"
import { default as placeholder } from "./en_US/placeholder.json"
import { default as label } from "./en_US/label.json"
import { default as menu } from "./en_US/menu.json"
import { default as message } from "./en_US/message.json"
import { default as warning } from "./en_US/warning.json"
import { default as state } from "./en_US/state.json"
import { default as license } from "./en_US/license.json"

const en_US = {
  breadcrumb,
  button,
  error,
  formError,
  placeholder,
  label,
  menu,
  message,
  warning,
  state,
  license,
}

export default en_US
