import React from "react"
import { useUser } from "@auth0/nextjs-auth0"
import AuthenticatedLayout from "./authenticated-layout"
import UnauthenticatedLayout from "./unauthenticated-layout"
import { ErrorView, LoadingView } from "../feedback"
import useRole from "../../hooks/use-role"
import { INSUFFICIENT_PERMISSIONS } from "../../constants/errors"

export interface BasicLayoutProps {}

const BasicLayout: React.FC<BasicLayoutProps> = ({ children }) => {
  const { user, error, isLoading } = useUser()
  const { operator, error: userRoleError, loading } = useRole()
  if (isLoading || loading) {
    return <LoadingView />
  }

  if (!operator) {
    return (
      <UnauthenticatedLayout>
        <ErrorView error={new Error(INSUFFICIENT_PERMISSIONS)} />
      </UnauthenticatedLayout>
    )
  }

  if (error || userRoleError) {
    return (
      <UnauthenticatedLayout>
        <ErrorView error={error || userRoleError} />
      </UnauthenticatedLayout>
    )
  }

  return user ? (
    <AuthenticatedLayout>{children}</AuthenticatedLayout>
  ) : (
    <UnauthenticatedLayout>{children}</UnauthenticatedLayout>
  )
}

export default BasicLayout
