import React, { useState } from "react"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { Col, Layout, Menu, Row } from "antd"
import UserMenu from "./user-menu"
import {
  BarsOutlined,
  ClusterOutlined,
  DatabaseOutlined,
  GoldOutlined,
  HeartOutlined,
  PictureOutlined,
  TagsOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons"
import Link from "next/link"
import logo from "../../../public/assets/images/arezzo-media-black.svg"
import Image from "next/image"
import classNames from "./authenticated-layout.module.scss"

export interface AuthenticatedLayoutProps {}

const { Header, Sider, Content } = Layout

const createAllPaths = (pathname: string): string[] => {
  const parts = pathname.replace("/", "").split("/")
  const result: string[] = []
  parts.map(part => {
    return result.push(`${result[result.length - 1] ?? ""}/${part}`)
  })

  return result
}

const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({
  children,
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const [defaultOpenKeys, setDefaultOpenKeys] = useState(
    createAllPaths(router.pathname)
  )

  return (
    <Layout className={classNames.container}>
      <Sider className={classNames.sidebar} width={300}>
        <div className={classNames.logo}>
          <Link passHref href={"/"}>
            <a>
              <Image src={logo} />
            </a>
          </Link>
        </div>
        <Menu
          className={classNames.menu}
          defaultSelectedKeys={defaultOpenKeys}
          defaultOpenKeys={defaultOpenKeys}
          mode="inline"
        >
          <Menu.SubMenu key="/contents" title={t("menu:contents")}>
            <Menu.Item key="/contents/categories" icon={<BarsOutlined />}>
              <Link href="/contents/categories">{t("menu:categories")}</Link>
            </Menu.Item>
            <Menu.Item key="/contents/taxonomies" icon={<GoldOutlined />}>
              <Link href="/contents/taxonomies">{t("menu:taxonomies")}</Link>
            </Menu.Item>
            <Menu.Item key="/contents/tags" icon={<TagsOutlined />}>
              <Link href="/contents/tags">{t("menu:tags")}</Link>
            </Menu.Item>
            <Menu.Item key="/contents/artworks" icon={<PictureOutlined />}>
              <Link href="/contents/artworks">{t("menu:artworks")}</Link>
            </Menu.Item>
            <Menu.Item key="/contents/albums" icon={<DatabaseOutlined />}>
              <Link href="/contents/albums">{t("menu:albums")}</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu key="/creators" title={t("menu:creators")}>
            <Menu.Item key="/creators/organizations" icon={<ClusterOutlined />}>
              <Link href="/creators/organizations">
                {t("menu:organizations")}
              </Link>
            </Menu.Item>
            <Menu.Item key="/creators/authors" icon={<TeamOutlined />}>
              <Link href="/creators/authors">{t("menu:authors")}</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu key="/users" title={t("menu:users")}>
            <Menu.Item key="/users/users" icon={<UserOutlined />}>
              <Link href="/users/users">{t("menu:users")}</Link>
            </Menu.Item>
            <Menu.Item key="/users/operators" icon={<UserOutlined />}>
              <Link href="/users/operators">{t("menu:operators")}</Link>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </Sider>
      <Layout>
        <Header className={classNames.header}>
          <Row justify="space-between" align="middle">
            <Col></Col>
            <Col>
              <UserMenu />
            </Col>
          </Row>
        </Header>
        <Content className={classNames["content-container"]}>
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default AuthenticatedLayout
