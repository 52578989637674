import React from "react"
import { Col, Layout, Row } from "antd"
import UserMenu from "./user-menu"
import Link from "next/link"

export interface UnauthenticatedLayoutProps {}

const { Header, Footer, Sider, Content } = Layout

const UnauthenticatedLayout: React.FC<UnauthenticatedLayoutProps> = ({
  children,
}) => {
  return (
    <Layout>
      <Header>
        <Row justify="space-between">
          <Col>
            <Link href="/">Logo</Link>
          </Col>
          <Col>
            <UserMenu />
          </Col>
        </Row>
      </Header>
      <Content>{children}</Content>
      <Footer>Footer</Footer>
    </Layout>
  )
}

export default UnauthenticatedLayout
