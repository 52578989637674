import moment from "moment"
import {
  FileImageOutlined,
  FileOutlined,
  FolderFilled,
} from "@ant-design/icons"
import React from "react"
import { message } from "antd"
import i18n from "../../lib/i18n"
import { AxiosError, AxiosResponse } from "axios"
import { IAuthor } from "../interfaces/models"
import { UserProfile } from "@auth0/nextjs-auth0"

export const devLog =
  process.env.NODE_ENV !== "production" ? console.log : (): void => {}

export const formatDate = (
  date: Date | string | null | undefined,
  format = "lll"
) => {
  if (!date) {
    return i18n.t("label:invalidDate")
  }

  const momentDate = moment(date)

  const diff = -momentDate.diff(moment(), "day")

  if (diff < 2) {
    return momentDate.fromNow()
  }

  if (!momentDate.isValid()) {
    return i18n.t("label:invalidDate")
  }

  return momentDate.format(format)
}

export const createError = (
  message: string,
  statusCode: number,
  description: string | null = null
) => {
  return {
    message,
    statusCode,
    description,
  }
}

export const uiHandleError = (
  error: Error | AxiosError<any> | AxiosResponse<any>
) => {
  devLog({ error })

  let _message = "generic"
  if ("message" in error) {
    _message = error.message
  }
  if ("response" in error) {
    _message =
      error.response?.data?.desription ||
      error.response?.data?.message ||
      error.response?.data?.error?.message ||
      _message
  } else if ("request" in error) {
    // case request has no response
    _message = "noResponse"
  }

  _message = i18n.t(`error:${_message}`)

  message.error(_message, 3)
}

export const getFileIcon = (mimeType: string): React.ReactNode => {
  const icons: { [key: string]: React.ReactNode } = {
    file: <FileOutlined />,
    "application/am-folder": <FolderFilled />,
    "image/png": <FileImageOutlined />,
  }

  return icons[mimeType] || icons.file
}

export const formatFileSize = (
  bytes: number | null | undefined,
  si = false,
  dp = 1
): string => {
  if (!bytes) {
    return "-"
  }

  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + " B"
  }

  const units = si
    ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + " " + units[u]
}

export const getUserAvatar = (
  user: UserProfile | IAuthor | null | undefined
): string => {
  const type = "bottts" // bottts

  return `https://avatars.dicebear.com/api/${type}/${encodeURIComponent(
    user?.email || ""
  )}.svg`
}

export const getAuthorDisplayName = (author?: IAuthor | null): string => {
  if (!author) {
    return ""
  }
  if (author.firstname && author.lastname) {
    return `${author.firstname} ${author.lastname}`
  }

  return author.email || ""
}

export const __DEV__ = process.env.NODE_ENV !== "production"

export const getImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.setAttribute("crossOrigin", "")
    img.onload = () => {
      resolve(img)
    }
    img.onerror = e => {
      reject(e)
    }
    img.src = url
  })
}

export const getColorsFromBuffer = (buffer: Buffer) => {}
